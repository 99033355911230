<template>
  <div class="ml-sub" @click="select" :class="{ active }">
    <div class="ml-sub_text">{{ text }}</div>
    <div class="ml-sub_price">
      <div class="ml-text-14-20-500">{{ price }}₽</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    text() {
      return this.item?.Name
    },
    price() {
      return this.item?.Price
    }
  },
  methods: {
    select() {
      this.$emit('change-sub', this.item)
    }
  }
}
</script>
