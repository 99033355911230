<template>
  <component :is="orderComponent"></component>
</template>

<script>
import NewCertificate from '@/components/Orders/NewCertificate'
import Subscription from '@/components/Orders/Subscription'
import { mapGetters } from 'vuex'
export default {
  components: {
    default: NewCertificate,
    ordersubscribe: Subscription
  },
  computed: {
    ...mapGetters('app', ['hasOrderPage', 'getOrderPageType']),
    orderComponent() {
      return this.getOrderPageType || 'default'
    }
  }
}
</script>
