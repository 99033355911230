<template>
  <div class="d-flex flex-column h100">
    <div class="wrapper-alert" v-if="alert">
      <div class="black-alert">
        <v-icon>mdi-check</v-icon> Изменения сохранены
      </div>
    </div>
    <div class="pb flex-grow-1">
      <div class="mb-3">
        <div class="section">{{ step1Name }}</div>
        <design-carousel
          style="margin-left: -33px; margin-right: -33px"
          class="design-carousel"
          @change-certificate="form.certificate = $event"
        />
      </div>
      <div>
        <div class="section">{{ step2Name }}</div>
        <div class="d-flex flex-row flex-wrap">
          <par
            :readonly="false"
            @input="selectedPar = null"
            v-model="customPar"
            :active="customPar !== null"
            :min="minFreeNominal"
            :max="maxFreeNominal"
          />
          <par
            @change-par="changePar"
            v-for="(par, index) in nominals"
            :value="par"
            :key="index"
            :active="par == selectedPar"
          />
        </div>
      </div>
      <div class="mb-9">
        <div>
          <div class="section">{{ step3Name }}</div>
          <MlTextarea
            v-model="form.congratulation"
            placeholder="Не забудьте написать несколько приятных слов получателю сертификата"
          />

          <div
            class="d-flex flex-row align-center justify-content-between pt-1"
          >
            <a href="#" @click.prevent="openPreview" v-if="hasPreviewRule">
              <img src="~@/assets/img/eye.png" v-if="!isAllowContinue" alt="" />
              <img src="~@/assets/img/eye-grey.svg" v-else alt="" />
              <span
                class="ml-text-14-20-500 ml-2 "
                :class="{ 'ml-text-grey2': isAllowContinue }"
                >Предпросмотр</span
              >
            </a>

            <!-- <a
              href="#"
              v-if="isUpdate"
              @click.prevent="removeCertificate"
              class="ml-text-14-20-500 ml-text-red"
            >
              Удалить из корзины
            </a> -->
            <a
              href="#"
              @click.prevent="setFriend"
              class="ml-silver-btn-sm ml-py-2px"
              v-if="isShowFriendBtn"
            >
              {{ friendName }}
            </a>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-8" v-if="isUpdate && currentCertificate">
        <MlNumeric2 max="10" v-model="form.count" @input="changeCount" />
      </div>
    </div>
    <div class="controlls" v-if="!isUpdate" ref="controlls">
      <button
        :disabled="isAllowContinue"
        @click.prevent="nextPage"
        class="ml-black-btn"
        style="width: 100%"
      >
        {{ titleNextBtn }}
      </button>
    </div>
    <div class="controlls" v-if="isUpdate" ref="controlls">
      <button @click.prevent="save" class="ml-black-btn" style="width: 100%">
        Сохранить изменения
      </button>
    </div>
  </div>
</template>

<script>
import par from '@/components/Panel/Par'
import MixinOrder from '@/helpers/mixins/order/order'

export default {
  mixins: [MixinOrder],
  components: {
    par
  },
  data: () => ({
    selectedPar: null,
    customPar: null
  }),
  computed: {
    orderPrice() {
      return this.customPar ?? this.selectedPar
    },
    minFreeNominal() {
      return this.orderConfig?.MinFreeNominal
    },
    maxFreeNominal() {
      return this.orderConfig?.MaxFreeNominal
    },
    nominals() {
      return this.orderConfig?.Nominal.map(item => item?.Nominal)
    }
  },
  methods: {
    loadPrice(price) {
      if (this.nominals.includes(price)) {
        this.selectedPar = price
      } else {
        this.customPar = price
      }
    },
    changePar(value) {
      this.selectedPar = value
      this.customPar = null
    }
  }
}
</script>
